<template>
  <ion-page>
    <ion-header v-if="isClubView">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push('/tabs/clubs/' + this.$route.params.id)"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.history')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <header-toolbar v-else>
      <ion-title>{{ __('interface.history') }}</ion-title>
    </header-toolbar>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list v-for="(items, key) in historyGroups" :key="key">
        <ion-list-header v-text="key"></ion-list-header>
        <ion-item v-for="(session, key) in items" :key="key" @click="actionSummary(session)">
          <avatar slot="start" :initials="getInitials(session)" :value="session.profile_photo_tn"/>
          <ion-label>
            <h3 v-text="session.date"/>
            <div>{{ session.name }}</div>
            <p v-if="session.calories"> Calories: {{ Math.round(session.calories) }}</p>
            <p v-if="session.avg_heart_rate"> Average heart rate: {{ Math.round(session.avg_heart_rate) }}</p>
            <p v-if="session.max_heart_rate"> Max heart rate: {{ session.max_heart_rate }}</p>
            <p v-if="session.duration_in_s"> Duration: {{ getDisplayDuration(session.duration_in_s) }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list v-if="Object.keys(historyGroups).length <= 0">
        <ion-item >
          <ion-label v-text="__('interface.no-results')"></ion-label>
        </ion-item>
      </ion-list>
    </ion-content>

  </ion-page>
</template>

<script>
import {
  IonListHeader, IonPage, IonRefresher, IonRefresherContent, loadingController
} from '@ionic/vue'
import {API} from "@/utils/apiWrapper"
import {mapState} from 'vuex'
import AuthView from '@/views/AuthService'
import {layout} from '@/utils/layout'
import HeaderToolbar from "../../layouts/HeaderToolbar"
import {Auth} from '@aws-amplify/auth'
import dayjs from 'dayjs'
import Avatar from "../../components/Avatar";

export default {
  name: 'History',
  mixins: [AuthView, layout],
  components: {
    IonListHeader,
    IonPage,
    IonRefresherContent,
    IonRefresher,
    HeaderToolbar,
    Avatar
  },
  data() {
    return {
      historyGroups: []
    }
  },
  setup() {
    return {}
  },
  computed: {
    ...mapState({
      currentUser: state => state.user
    }),
    isClubView() {
      return false
    }
  },
  props: {},
  methods: {
    getDisplayDuration(duration) {
      const minutes = ('0' + Math.floor(duration / 60)).slice(-2)
      const seconds = ('0' + duration % 60).slice(-2)

      return `${minutes}m ${seconds}s`
    },
    getInitials(session) {
      let initials = ''
      const name = session.name ?? ''

      name.split(' ').forEach(word => {
        initials += word.substring(0, 1)
      })

      return initials
    },
    actionSummary(data) {
      this.$router.push({
        name: 'HistorySummary',
        params: {
          id: data.id,
          contextDate: dayjs(data.start_date).toISOString()
        }
      });
    },
    formatDate(dateString) {
      const sessionDate = new Date(dateString)

      return dayjs(sessionDate).format('MM.YYYY')
    },
    parseResponse(response) {
      response.forEach(x => {
        x.avg_heart_rate = this.formatFloat(x.avg_heart_rate)
        x.calories = this.formatFloat(x.calories)
      })

      const sortedResponse = response.sort(function (a, b) {
        return a.date.localeCompare(b.date)
      }).reduce((groups, session) => {

        const date = this.formatDate(session.date)
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push({
          ...session,
          date: new Date(session.date).toLocaleString()
        })
        return groups
      }, {})

      return sortedResponse
    },
    loadData() {
      return API.get('TheSpottApi', '/history-customer').then(response => {
        this.historyGroups = this.parseResponse(response.data)
      })
    },
    doRefresh(event) {
      this.loadData()
        .catch((err) => console.log(err))
        .finally(() => {
          event.target.complete()
        })
    },
    async ionViewWillEnter() {
      await this.checkAuth()
    },
    async ionViewDidEnter() {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      })
      await loading.present()

      Auth.currentAuthenticatedUser().catch(() => {
        this.$router.push('/login')
      })

      this.loadData().finally(() => {
        loading.dismiss()
      })
    },
    formatFloat(original) {
      if (!original)
        return original;

      return Math.round(parseFloat(original) * 100) / 100;
    }
  }
}
</script>
